<template>
    <div class="meo-login">
        <v-overlay class="p-absolute" color="white" opacity="0.8" v-if="loading">
            <v-progress-circular :size="60" :width="6" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-container px-5 pb-6 v-if="!resetSent && !error" class="align-center d-flex flex-column">
            <form @submit.prevent="submit" class="width-half">
                <v-row justify="center">
                    <v-col class="d-flex justify-center">
                        <h2 class="text-no-wrap my-6">Reset Password</h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="password"
                            v-model="password"
                            class="pt-0 my-5 white-label white-button white-input white-text"
                            color="white"
                            label="New Password"
                            placeholder="Please enter a password..."
                            required
                            :rules="[rules.required, rules.minLength6]"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="password"
                            v-model="confirmPassword"
                            underlined
                            single-line
                            class="pt-0 my-5 white-label white-button white-input white-text"
                            color="white"
                            label="Confirm Password"
                            placeholder="Please confirm password..."
                            required
                            :rules="[rules.isMatch]"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block x-large small type="submit">
                            <span class="primary--text">Confirm </span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.login' }" small text color="black">
                            <span class="text-capitalize font-weight-regular">Cancel</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>
        <v-container px-5 pb-6 v-else-if="error">
            <v-row justify="center">
                <v-col class="shrink d-flex justify-center">
                    <h1 class="text-no-wrap">Reset Password</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p v-text="error"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="white" :to="{ name: 'auth.login' }"> Done </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container px-5 pb-6 v-else>
            <v-row justify="center">
                <v-col class="shrink d-flex justify-center">
                    <h1 class="text-no-wrap">Reset Password</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <p>Your password has been successfully reset.</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large color="white" :to="{ name: 'modules' }"> Done </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";
import { mapState } from "vuex";
/////////////////////////////////////////

export default {
    props: ["token"],
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Reset | %s",
    },
    data() {
        return {
            details: null,
            loading: true,
            error: "",
            resetSent: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            rules: {
                required: (value) => !!value || "Required.",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
            },
        };
    },
    created() {
        //When we first get to this page
        //we want to send the token to the server
        //so we can update the user's details

        //Check if there is an application
        var applicationContext = this.application && this.application.authenticationStyle == "application";

        this.$fluro.auth
            .retrieveUserFromResetToken(this.token, {
                application: applicationContext,
            })
            .then((userDetails) => {
                this.details = userDetails;

                //Update our state with the details
                this.firstName = userDetails.firstName;
                this.lastName = userDetails.lastName;
                this.email = userDetails.username || userDetails.email;
                this.lastName = userDetails.lastName;

                //We can stop loading now
                this.loading = false;
            })
            .catch((err) => {
                //There was a fail
                var message = this.$fluro.utils.errorMessage(err);

                // this.error = message
                this.loading = false;
            });
    },
    computed: { ...mapState(["assets"]) },
    methods: {
        submit() {
            this.loading = true;

            var payload = {
                username: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                password: this.password,
                confirmPassword: this.confirmPassword,
            };

            /////////////////////////////////

            //Check if there is an application
            var applicationContext = this.application && this.application.authenticationStyle == "application";

            //Use the token to update the users details and login in as them
            this.$fluro.auth
                .updateUserWithToken(this.token, payload, { application: applicationContext })
                .then((user) => {
                    //Hi there user.firstName!
                    console.log("Were good!");
                    this.resetSent = true;
                    this.loading = false;
                })
                .catch(function (err) {
                    //There was a fail
                    var message = this.$fluro.utils.errorMessage(err);
                    this.error = message;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.width-half {
    width: 60%;
}

// .white-label label {
//   color: white !important;
//   border-color: white;
// }
// .white-button button {
//   color: white !important;
//   border-color: white;
// }

// .white-text input {
//   color: white !important;
//   border-color: white;
// }
// .v-text-field > .v-input__control > .v-input__slot:before {
//   border-color: white !important;
//   border-style: solid;
//   border-width: thin 0 0 0;
// }
</style>
